import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
const { VUE_APP_API_HOST } = process.env;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        width: 0,
        navLinks: [
            {to: "/projects"},
            {to: "/vacancy"},
            {to: "/contacts"},
        ],
        logos: [
            {logo: "simfony.svg", text: "Symfony"},
            {logo: "go.svg", text: "Go"},
            {logo: "python.svg", text: "Python"},
            {logo: "mysql.svg", text: "MySQL"},
            {logo: "clickhouse.svg", text: "Clickhouse"},
            {logo: "redis.svg", text: "Redis"},
            {logo: "mongodb.svg", text: "MongoDB"},
            {logo: "tarantool.svg", text: "Tarantool"},
            {logo: "docker.svg", text: "Docker"},
            {logo: "kubernets.svg", text: "Kubernetes"},
            {logo: "git.svg", text: "Git"},
            {logo: "cicd.svg", text: "CI/CD"},
        ],
        projects: [
            {gif: "marfa.gif"},
            {gif: "asomarket.gif"},
            {gif: "push.gif"},
            {gif: "aramuz.gif"},
            {gif: "mobapp.gif"},
            {gif: "bl.gif"},
        ],
        facts: [
            {img: "code.svg"},
            {img: "mount.svg"},
            {img: "team.svg"},
            {img: "artificial.svg"},
            {img: "victory.svg"},
            {img: "cup.svg"},
        ],
        isVacanciesLoading: false,
        vacanciesList: []
    },

    getters: {
        getNavLinks: (state) => {
            return state.navLinks
        },
        getProjects: (state) => {
            return state.projects
        },
        getFacts: (state) => {
            return state.facts
        },
        getVacancyById: (state) => (id) => {
            return state?.vacanciesList?.find(vacancy => vacancy._id === id);
        },
        availableDepartments: (state) => {
            return [...new Set(state?.vacanciesList?.map(vacancy => vacancy.department))];
        }
    },

    mutations: {
        setWidth: (state) => {
            state.width = window.innerWidth;
        },
        setVacanciesIsLoading(state, payload) {
            state.isVacanciesLoading = payload;
        },
        setVacanciesList(state, payload) {
            state.vacanciesList = payload;
        }
    },

    actions: {
        async getVacanciesList({ commit }, limit = null) {
            commit('setVacanciesIsLoading', true);
            try {
                const body = {
                    query:
                        'query ($limit: Int) {\n  MarfaVacanciess(limit: $limit) {\n    items {\n      _id\n      advantages\n      description\n      expect\n      name\n      offer\n      what_to_do\n      department\n    }\n  }\n}',
                    variables: {
                        limit,
                    }
                };
                const res = await axios.post(VUE_APP_API_HOST, body);
                commit('setVacanciesList', res.data?.data?.MarfaVacanciess?.items || []);
            } catch (e) {
                console.log(e);
            } finally {
                commit('setVacanciesIsLoading', false);
            }
        },
    },
});
