import '@/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import VModal from "vue-js-modal";
import Meta from 'vue-meta';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import Multiselect from 'vue-multiselect';
import VueScreen from 'vue-screen';

Vue.use(VueScreen);
Vue.component('multiselect', Multiselect);
Vue.use(VModal);
Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true,
});

new Vue({
    i18n,
    store,
    router,
    render: (h) => h(App)
}).$mount('#app');
