<template>
  <nav class="NavList">
    <LocalizedLink to="/" class="NavList-Logo">
      <img v-if="$screen.width > 1262" src="@/assets/img/logo.svg" alt="logo" class="Logo">
      <img v-else src="@/assets/img/logo-mini.svg" alt="logo" class="Logo">
    </LocalizedLink>
    <div class="NavList-Links">
      <LocalizedLink
          v-for="(link, i) in mergeArrays"
          :key="i"
          :to="link.to" class="NavList-Link"
      >
        {{ link.name }}
      </LocalizedLink>
    </div>
  </nav>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";

export default {
  name: "NavList",
  components: {
    LocalizedLink
  },
  computed: {
    nav() {
      return this.$store.getters.getNavLinks
    },
    mergeArrays() {
      let mergeArr = this.nav.map((info, index) => ({...info, ...this.$t('menu')[index]}));
      return mergeArr
    }
  },
}
</script>

<style lang="scss">
.NavList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.27;

  @media(min-width: $screen-xl) {
    font-size: 18px;
  }

  &-Link {
    margin-right: 31px;
    transition: .3s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: var(--color-text-hover);
    }

    @media(min-width: $screen-xl) {
      margin-right: 81px;
    }
  }

  .router-link-exact-active {
    color: var(--color-text-hover);
  }
}
</style>