import Vue from 'vue'
import VueRouter from 'vue-router'
// import i18n from "../plugins/i18n";

const HomePage = () => import(/* webpackChunkName: "homePage" */ '@/pages/HomePage.vue');
const ProjectsPage = () => import(/* webpackChunkName: "homePage" */ '@/pages/ProjectsPage.vue');
const VacancyPage = () => import(/* webpackChunkName: "homePage" */ '@/pages/VacancyPage.vue');
const ContactsPage = () => import(/* webpackChunkName: "homePage" */ '@/pages/ContactsPage.vue');
const VacancyDetail = () => import(/* webpackChunkName: "homePage" */ '@/pages/vacancy/_vacancy.vue');

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: {
            template: '<router-view></router-view>',
        },

        children: [
            {
                path: '',
                name: 'HomePage',
                component: HomePage,
            },
            {
                path: 'projects',
                name: 'projects',
                component: ProjectsPage,
            },
            {
                path: 'vacancy',
                name: 'vacancy',
                component: VacancyPage,
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: ContactsPage,
            },
            {
                path: 'vacancy-detail/:id',
                name: 'vacancydetail',
                component: VacancyDetail,
            },
        ],
    },
    {
        path: '*',
        redirect() {
            return '/'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'};
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth'
        };
    },
})

export default router