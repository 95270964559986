<template>
  <div class="MainNav" :class="{ 'MainNav--bg': documentIsScrolled }">
    <div class="Container">
      <NavList />
    </div>
  </div>
</template>

<script>
import NavList from "@/components/NavList";

export default {
  name: "MainNav",
  components: {
    NavList
  },
  data() {
    return {
      documentIsScrolled: false,
    }
  },
  computed: {

  },
  mounted() {
    if (window.scrollY > 50) this.documentIsScrolled = true;
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
 methods: {
   onScroll() {
     this.documentIsScrolled = window.scrollY > 50;
   },
 }
}
</script>

<style lang="scss">
.MainNav {
  padding: 14px 0 10px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 666;
  color: var(--color-text-main2);
  transition: .3s;

  &--bg {
    background-color: var(--color-body);
    box-shadow: 0px 4px 4px rgba(52, 52, 74, 0.1);
  }

  @media(min-width: $screen-xl) {
    padding: 22px 0 18px;
  }
}
</style>