<template>
  <div id="app">
    <MainNav/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>

import MainNav from "@/components/MainNav";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {
    Footer,
    MainNav
  },
  data() {
    return {

    }
  },
  /* metaInfo() {
     return {
       title: [this.$t('title')],
       meta: [
         {
           name: this.$t('metaInfo.name'),
           content: this.$t('metaInfo.description'),
         },
         {
           property: 'og:title',
           content: 'Marfatech',
         },
         {
           property: 'og:url',
           content: 'https://marfa-tech.com/',
         },
         {
           property: 'og:description',
           content: this.$t('metaInfo.ogDescrCont'),
         },
         {
           property: 'og:type',
           content: 'website',
         },
         {
           property: 'og:img',
           content: 'https://marfa-tech.com/logo.svg',
         }
       ],
     };
   },*/
}
</script>

<style>

</style>