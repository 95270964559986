<template>
  <div class="Footer">
    <div class="Container">
      <NavList />
    </div>
  </div>
</template>

<script>
import NavList from "@/components/NavList";
export default {
  name: "Footer",
  components: {NavList}
}
</script>

<style lang="scss">
.Footer {
  padding: 22px 0 18px;
}
</style>